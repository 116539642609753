import { Field, Formik, Form, ErrorMessage } from "formik";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import React from "react";
import * as Yup from "yup";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import CryptoJS from "crypto-js";
import { OtherContext } from "./Context";
import { useQuery, useQueryClient } from "react-query";
// import dayjs from "dayjs";

const Other = ({ children }) => {

    let CryptoJSKey = "MultipleRevolutionLawyer@123";
    // const navigate = useNavigate();
    const gobalUrl = "https://lawyerbackend.multiplerevolution.online";
    // const gobalUrl = "http://localhost:3004";
    const systemDT = dayjs().format("YYYY-MM-DD HH:mm:ss");
    const cookiesDomain = window.location.hostname;
    // const cookiesDomain = "lawyercrm.multiplerevolution.online";

    const setLocalData = function (value) {
        const encrypted = CryptoJS.AES.encrypt(value, CryptoJSKey).toString();
        return encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');  // URL encode the encrypted string
    }

    // Decryption function
    const decryptDataBase64Url = (encryptedData) => {
        // Reverse the Base64 URL-safe encoding
        const decodedData = encryptedData.replace(/-/g, '+').replace(/_/g, '/');
        // Decrypt the data
        const bytes = CryptoJS.AES.decrypt(decodedData, CryptoJSKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    };


    const setCookie = (name, value) => {
        Cookies.set(name, value, {
            domain: window.location.hostname,
            path: "/",
            expires: 365,
        });
    };

    const notify = (msg, type) => {
        type === "success" ? toast.success(msg) : toast.error(msg);
    };

    return (
        <OtherContext.Provider
            value={{
                gobalUrl,
                systemDT,
                toast,
                setCookie,
                dayjs,
                useQuery,
                useMutation,
                useQueryClient,
                Field,
                Formik,
                Form,
                ErrorMessage,
                Yup,
                NavLink,
                useNavigate,
                useParams,
                Cookies,
                cookiesDomain,
                notify,
                setLocalData,
                decryptDataBase64Url
            }}
        >
            {children}
        </OtherContext.Provider>
    );
};

export default Other;
