import React, { useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import { useMui, useOther } from "../hooks/Hooks";
import Skeleton from "@mui/material/Skeleton";
import axios from "../services/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 2,
};

const AttendanceModal = ({ open, handleClose, attendanceData }) => {
  const { Cookies, useMutation, notify } = useOther();
  const { Modal, Box, Button, Typography, CircularProgress } = useMui();

  const userAttendanceData = attendanceData?.data?.data?.[0];

  console.log(userAttendanceData, "userAttendanceData");

  const webcamRef = useRef(null);
  const [url, setUrl] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cameraLoading, setCameraLoading] = useState(true);
  const [error, setError] = useState(null);

  // React Query Mutation for adding attendance
  const mutation = useMutation(
    async (data) => {
      return await axios.post("/attendance/save-user-attendance", data); // Adjust the endpoint URL as per your API
    },
    {
      onSuccess: () => {
        attendanceData.refetch();
        notify("Attendance saved successfully!", "success");
        setUrl(null);
        handleClose();
      },
      onError: (err) => {
        notify("Failed to save attendance.", "error");
        // alert(err.response?.data?.error || "Failed to save attendance.");
      },
    }
  );

  const capturePhoto = useCallback(async () => {
    setLoading(true);
    const imageSrc = webcamRef.current?.getScreenshot();

    if (!imageSrc) {
      setError(
        "Unable to capture image. Please check your camera permissions."
      );
      setLoading(false);
      return;
    }

    if ("geolocation" in navigator) {
      let options = {
        timeout: 10000,
        enableHighAccuracy: true,
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setCurrentLocation(`${lat},${lng}`);
          setUrl(imageSrc);
          setError(null);
        },
        () => {
          setError(
            "Unable to fetch location. Please check location permissions."
          );
        },
        options
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }

    setLoading(false);
  }, []);

  const handleSave = (type) => {
    if (!url || !currentLocation) {
      alert("Please capture photo and allow location access.");
      return;
    }

    mutation.mutate({
      user_id: Cookies.get("user_id"),
      photo_url: url,
      location: currentLocation,
      type,
    });
  };

  const videoConstraints = {
    width: 300,
    height: 200,
    facingMode: "user",
  };

  const handleCameraLoad = () => {
    setCameraLoading(false);
    setError(null);
  };

  const handleCameraError = () => {
    setCameraLoading(false);
    setError("Unable to access the camera. Please check permissions.");
  };

  // Fallback if camera fails to load in 5 seconds
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (cameraLoading) {
        setCameraLoading(false);
        setError("Camera failed to load. Please check permissions.");
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [cameraLoading]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {url === null ? (
          <>
            {cameraLoading ? (
              <Skeleton
                variant="rectangular"
                width={400}
                height={300}
                style={{ borderRadius: "5px" }}
              />
            ) : (
              <Webcam
                ref={webcamRef}
                audio={false}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                onUserMedia={handleCameraLoad}
                onUserMediaError={handleCameraError}
                className="w-100"
                style={{ borderRadius: "5px" }}
              />
            )}
            {error && (
              <Typography
                variant="body2"
                color="error"
                sx={{ mt: 2, textAlign: "center" }}
              >
                {error}
              </Typography>
            )}
            <Box sx={{ textAlign: "center" }} className="mt-3">
              <Button
                variant="outlined"
                onClick={capturePhoto}
                disabled={loading || cameraLoading}
                startIcon={loading && <CircularProgress size={16} />}
              >
                {loading ? "Capturing..." : "Capture"}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <img src={url} alt="Screenshot" className="user-attendance-img" />
            <div className="w-100 mt-3 d-flex justify-content-between">
              <Button variant="outlined" onClick={() => setUrl(null)}>
                Retake
              </Button>
              <Button
                variant="outlined"
                onClick={() =>
                  handleSave(userAttendanceData ? "out_time" : "in_time")
                }
                disabled={mutation.isLoading}
              >
                {mutation.isLoading ? "Saving..." : "In Time"}
              </Button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default AttendanceModal;
