import React, { useState, useRef, useEffect } from "react";
import { useBootstrap, useMui, useOther } from "../../hooks/Hooks";
import { CustomDescription, CustomHeading } from "../../utils/Common";
import useCIRPTaskHook from "../../hooks/useCIRPTaskHook";
import { ArrowBack, Delete } from "@mui/icons-material";
import axios from "../../services/axios";
import "./../../styles/CirpTask.css";
import { FileText, X } from "lucide-react";

const CaseRunTaskDetails = () => {
  const { Card } = useBootstrap();
  const { Button, TextField, Box, IconButton, Typography } = useMui();
  const {
    useQuery,
    useParams,
    systemDT,
    toast,
    useNavigate,
    Cookies,
    gobalUrl,
  } = useOther();
  const { getCIRPTaskDetailsHook } = useCIRPTaskHook();

  const { task_id, id } = useParams();
  const navigate = useNavigate();

  // State to handle file uploads and comment
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [comment, setComment] = useState("");
  const [isDragOver, setIsDragOver] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  // Ref for the hidden input element
  const fileInputRef = useRef(null);

  const notify = (msg, type) => {
    type === "success" ? toast.success(msg) : toast.error(msg);
  };

  // Handle file selection and preview
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  // Handle drag events for drag-and-drop file uploading
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    setIsDragOver(false);
  };

  // Handle click on drag-and-drop area to trigger file input
  const handleClick = () => {
    fileInputRef.current.click(); // Programmatically trigger the file input click
  };

  // Handle comment change
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  // Remove selected file
  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Fetching task details using the task_id
  const { data: taskDetails, isLoading } = useQuery(
    `TaskDetails-${task_id}`,
    () => getCIRPTaskDetailsHook([task_id, id])
  );

  // useEffect to set the comment and documents when taskDetails is loaded
  useEffect(() => {
    if (taskDetails && taskDetails.length > 0) {
      setComment(taskDetails[0]?.comment || ""); // Set the comment from taskDetails
      const parsedDocuments = JSON.parse(taskDetails[0]?.documents || "[]");
      setDocuments(parsedDocuments); // Set the parsed documents
    }
  }, [taskDetails]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleCompleteTask = async () => {
    setLoading(true);
    try {
      const formData = new FormData();

      // Append existing documents to FormData
      documents.forEach((doc) => {
        formData.append("documents[]", doc);
      });

      // Append new files to FormData
      selectedFiles.forEach((file) => {
        formData.append("documents[]", file);
      });

      formData.append("comment", comment);
      formData.append("task_id", task_id);
      formData.append("systemDT", systemDT);
      formData.append("done_by", Cookies.get("user_id"));

      // Make the API request to your backend
      const response = await axios.post(
        "/cirp-task/complete-cirp-task-status",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      notify("CIRP Task Completed", "success");
      navigate(-1);

      // Optionally, reset the form state
      setSelectedFiles([]);
      setComment("");
    } catch (error) {
      // Handle error response
      console.error("Error submitting the task details:", error);
      notify(
        "There was an error submitting the task details. Please try again.",
        "error"
      );
    }
    setLoading(false);
  };

  console.log(taskDetails);

  // Handle form submission
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const formData = new FormData();

      // Append files to FormData
      selectedFiles.forEach((file) => {
        formData.append("documents[]", file);
      });

      // Append other form data
      if (taskDetails[0].cct_id) {
        formData.append("previous_documents", JSON.stringify(documents)); // Convert documents to JSON stringdocuments);
        formData.append("cct_id", taskDetails[0].cct_id);
      }

      // Append other form data
      formData.append("comment", comment);
      formData.append("task_id", task_id);
      formData.append("cirp_id", id);
      formData.append("systemDT", systemDT);
      formData.append("done_by", Cookies.get("user_id"));
      // console.log(taskDetails[0].cct_id);
      // Make the API request to your backend
      const response = await axios.post(
        "/cirp-task/update-cirp-task-status",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        notify("CIRP task sended for review", "success");
        navigate(-1);
        // Optionally, reset the form state
        setSelectedFiles([]);
        setComment("");
      } else {
        notify(
          "There was an error submitting the task details. Please try again.",
          "error"
        );
      }
    } catch (error) {
      // Handle error response
      notify(
        "There was an error submitting the task details. Please try again.",
        "error"
      );
      console.error("Error submitting the task details:", error);
    }
    setLoading(false);
  };

  // Helper function to display file preview (for images) or just file name
  const renderFilePreview = (file, index) => {
    if (file.type.startsWith("image/")) {
      return (
        <div
          key={index}
          style={{ marginBottom: "10px" }}
          className="task-file-card"
        >
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            style={{
              width: "-webkit-fill-available",
              height: "-webkit-fill-available",
            }}
          />
          <IconButton
            onClick={() => handleRemoveFile(index)}
            size="small"
            color="error"
            className="task-file-close-icon"
          >
            <X color="#fff" />
          </IconButton>
        </div>
      );
    }
    return (
      <div
        key={index}
        className="task-file-card d-flex align-items-center justify-content-center flex-column"
      >
        <FileText color="#333" size={44} />
        <Typography className="mt-2">{file.name}</Typography>
        <IconButton
          onClick={() => handleRemoveFile(index)}
          size="small"
          color="error"
          className="task-file-close-icon"
        >
          <X color="#fff" />
        </IconButton>
      </div>
    );
  };

  return (
    <Card className="custom-card">
      <Card.Header className="d-flex justify-content-between align-items-center p-3">
        <div>
          <CustomHeading
            Heading={"Task ID :- " + taskDetails[0]?.cirp_task_id}
          />
          <p style={{ color: "#a6a6a6", fontSize: "14px" }} className="m-0">
            {"Due Date :- " + taskDetails[0]?.day_of_complete}
          </p>
        </div>
        <Button
          variant="contained"
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Card.Header>

      <Card.Body className="p-3 d-flex justify-content-between align-items-center">
        <div>
          <CustomHeading Heading={taskDetails[0]?.task_label} />
          {taskDetails[0]?.task_desc && (
            <CustomDescription Description={taskDetails[0]?.task_desc} />
          )}
        </div>
        <div>
          {taskDetails[0].completed_on && (
            <p style={{ color: "#a6a6a6", fontSize: "14px" }} className="m-0">
              {"Completed on" + " " + taskDetails[0]?.completed_on}
            </p>
          )}
          {/* {taskDetails[0].reviewer_username && ( */}
            <p style={{ color: "#a6a6a6", fontSize: "14px" }} className="m-0">
              {"submitted by" + " " + taskDetails[0]?.reviewer_username}
            </p>
          {/* )} */}
          {taskDetails[0].done_by_username && (
            <p style={{ color: "#a6a6a6", fontSize: "14px" }} className="m-0">
              {"Review & Submitted  by" +
                " " +
                taskDetails[0]?.done_by_username}
            </p>
          )}
        </div>
      </Card.Body>

      {/* Existing Documents Section */}
      <Card.Body className="p-3">
        {documents.length > 0 && (
          <Typography variant="subtitle1">Existing Documents:</Typography>
        )}
        <div className="d-flex flex-wrap">
          {documents.map((doc, index) => {
            const fileUrl = `${gobalUrl}/uploads/cirp/task/${doc}`;
            const fileExtension = doc.split(".").pop().toLowerCase();
            const isImage = ["jpg", "jpeg", "png", "gif", "webp"].includes(
              fileExtension
            );

            return (
              <div
                key={index}
                className={
                  "task-file-card" +
                  (isImage
                    ? ""
                    : " d-flex align-items-center justify-content-center flex-column")
                }
              >
                {isImage ? (
                  <img
                    src={fileUrl}
                    alt={doc}
                    style={{
                      width: "-webkit-fill-available",
                      height: "-webkit-fill-available",
                    }}
                  />
                ) : (
                  <div className="d-flex flex-column gap-2 align-items-center">
                    <FileText color="#333" size={44} />
                    <a
                      href={fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "#333",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginTop: "10px",
                      }}
                    >
                      {doc}
                    </a>
                  </div>
                )}
                <IconButton
                  onClick={() => {
                    setDocuments((prevItems) =>
                      prevItems.filter((_, id) => id !== index)
                    );
                  }}
                  // onClick={() => handleRemoveFile(index)} // Assuming you want to remove it
                  size="small"
                  color="error"
                  className="task-file-close-icon"
                >
                  <X color="#fff" />
                </IconButton>
              </div>
            );
          })}
        </div>
      </Card.Body>

      {/* File upload section with drag-and-drop support */}
      <Card.Body className="p-3">
        <Box
          sx={{
            border: "2px dashed #ccc",
            padding: "20px",
            borderRadius: "8px",
            backgroundColor: isDragOver ? "#f0f0f0" : "#fff",
            textAlign: "center",
            cursor: "pointer",
            height: "100px",
          }}
          onClick={handleClick} // Trigger file input click when clicked
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Typography>
            {isDragOver
              ? "Release to upload files"
              : "Drag and drop files here or click to select files"}
          </Typography>
          <input
            ref={fileInputRef} // Referencing the input
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </Box>

        {/* File Preview */}
        {selectedFiles.length > 0 && (
          <div>
            <Typography variant="subtitle1">Selected Files:</Typography>
            <div className="d-flex flex-wrap justify-content-center align-items-center gap-2">
              {selectedFiles.map((file, index) =>
                renderFilePreview(file, index)
              )}
            </div>
          </div>
        )}
      </Card.Body>

      {/* Comment textarea section */}
      <Card.Body className="p-3">
        <TextField
          label="Comment"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={comment}
          onChange={handleCommentChange}
          placeholder="Enter your comment here"
        />
      </Card.Body>

      {/* Submit Button */}
      <Card.Body className="p-3 d-flex justify-content-between align-items-center">
        <Button
          variant="contained"
          color="primary"
          onClick={loading ? null : handleSubmit}
          disabled={loading}
        >
          {loading ? "Loading..." : "Submit"}
        </Button>
        {/* {Cookies.get("role") === "master" && ( */}
        {/* {taskDetails[0].reviewer_username && ( */}
          <Button
            variant="contained"
            onClick={handleCompleteTask}
            disabled={loading}
          >
            {loading ? "Loading..." : "Review & Complete Task"}
          </Button>
        {/* )} */}

        {/* )} */}
      </Card.Body>
    </Card>
  );
};

export default CaseRunTaskDetails;
