import React, { useState, useRef } from "react";
import { useBootstrap, useMui, useOther } from "../../hooks/Hooks";
import CIRPHeader from "../../components/CIRPHeader";
import Table from "react-bootstrap/Table";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import FormUtils from "../../utils/FormUtils";
import { FieldArray, Formik, Form } from "formik";
import { Col } from "react-bootstrap";
import { Delete } from "@mui/icons-material";
import useCaseMeetingHook from "../../hooks/CIRP/useCaseMeetingHook";
import Cookies from "js-cookie";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CustomModal from "../../components/CustomModal/CustomModal";
import { ImageUp, Minus, Plus } from "lucide-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CirpMeeting = () => {
  const { Card, Row } = useBootstrap();
  const { Divider, Button, Box, List, ListItem } = useMui();
  const {
    useNavigate,
    useParams,
    useQuery,
    systemDT,
    dayjs,
    gobalUrl,
    setLocalData,
  } = useOther();

  const navigate = useNavigate();
  const formUtils = FormUtils();

  const { CustomInputField, CustomFormGroup } = formUtils;
  const { getCirpCaseMeetingHook, useCirpCasePostMeetingHook } =
    useCaseMeetingHook();
  const { id } = useParams();

  const [loading, setLoading] = React.useState(false);
  const [addMeeting, setAddMeeting] = React.useState(false);
  const [postMeeting, setPostMeeting] = React.useState({
    status: false,
    data: [],
  });

  console.log(postMeeting);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  // const { mutate: postMutate, isLoading: postisLoading } =
  //   useCirpCasePostMeetingHook();

  const AllCirpCaseMeeting = useQuery(`all-cirp-case-meeting-${id}`, () =>
    getCirpCaseMeetingHook(id)
  );

  // Ref for the hidden input element
  const fileInputRef = useRef(null);

  // Handle file selection and preview
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  // Handle drag events for drag-and-drop file uploading
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    setIsDragOver(false);
  };

  // Handle click on drag-and-drop area to trigger file input
  const handleClick = () => {
    fileInputRef.current.click(); // Programmatically trigger the file input click
  };

  // Remove selected file
  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Helper function to display file preview (for images) or just file name
  const renderFilePreview = (file, index) => {
    if (file.type.startsWith("image/")) {
      return (
        <div key={index} style={{ marginBottom: "10px" }}>
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            style={{ maxHeight: "100px", marginRight: "10px" }}
          />
          <IconButton
            onClick={() => handleRemoveFile(index)}
            size="small"
            color="error"
          >
            <Delete />
          </IconButton>
        </div>
      );
    }
    return (
      <ListItem
        key={index}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography>{file.name}</Typography>
        <IconButton
          onClick={() => handleRemoveFile(index)}
          size="small"
          color="error"
        >
          <Delete />
        </IconButton>
      </ListItem>
    );
  };

  const handleAddMeeting = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("cirp_id", id);
      formData.append("created_by", Number(Cookies.get("user_id")));
      formData.append("systemDT", systemDT);
      formData.append("meeting_title", values.meeting_title);
      formData.append("meeting_venue", values.meeting_venue);
      formData.append("start_date_time", values.start_date_time);
      formData.append(
        "meeting_participants",
        JSON.stringify(values.meeting_participants)
      );
      formData.append(
        "discussion_agendas",
        JSON.stringify(values.discussion_agendas)
      );
      formData.append("description", values.description);

      selectedFiles.forEach((file) => {
        if (file) formData.append("circulated_notice_docs", file);
      });

      const response = await fetch(
        `${gobalUrl}/cirp/case-meeting/create-new-cirp-case-meeting`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to add meeting");
      }
      console.log("Meeting added successfully");
      AllCirpCaseMeeting.refetch();
      setAddMeeting(false); // Close dialog on successful submit
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
    setLoading(false);
  };

  // const handlePostMeeting = (values) => {
  //   postMutate(
  //     { ...values, meeting_id: postMeeting?.data[0].value },
  //     {
  //       onSuccess: (response) => {
  //         setPostMeeting({
  //           status: false,
  //           data: [],
  //         });
  //         AllCirpCaseMeeting.refetch();
  //       },
  //     }
  //   );
  // };

  return (
    <>
      <Card className="custom-card">
        <Card.Body className="px-3 pt-3">
          <CIRPHeader />
          <Divider />
        </Card.Body>
      </Card>
      <Card className="custom-card">
        <Card.Body className="px-3 p-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p>Meeting</p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAddMeeting(true)}
            >
              Add Meeting
            </Button>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>MEETING TITLE</th>
                <th>START DATE AND TIME</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {AllCirpCaseMeeting?.data?.map((data, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    navigate(`${setLocalData(JSON.stringify(data))}`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td>{index + 1}</td>
                  <td>{data.meeting_title}</td>
                  <td>
                    {dayjs(data.start_date_time).format("DD/MM/YYYY hh:mm A")}
                  </td>
                  <td>{data.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <CustomModal
        ModalWidth={800}
        open={addMeeting}
        handleClose={() => setAddMeeting(false)}
        children={
          <Formik
            initialValues={{
              meeting_title: "",
              meeting_venue: "",
              start_date_time: "",
              discussion_agendas: [""],
              meeting_participants: [{ name: "", voting_percentage: "" }],
              circulated_notice_docs: [],
              description: "",
            }}
            onSubmit={handleAddMeeting}
          >
            {({ values, touched, errors, setFieldValue }) => (
              <Form>
                <Row className="align-items-center py-3">
                  <CustomFormGroup
                    formlabel="Meeting title"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="meeting_title"
                        placeholder="Enter Meeting title"
                        error={touched.meeting_title && !!errors.meeting_title}
                        helperText={
                          touched.meeting_title && errors.meeting_title
                        }
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="Meeting Venue"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="meeting_venue"
                        placeholder="Enter Meeting Venue"
                        error={touched.meeting_venue && !!errors.meeting_venue}
                        helperText={
                          touched.meeting_venue && errors.meeting_venue
                        }
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="Start Date and Time"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="datetime-local"
                        name="start_date_time"
                        error={
                          touched.start_date_time && !!errors.start_date_time
                        }
                        helperText={
                          touched.start_date_time && errors.start_date_time
                        }
                      />
                    }
                  />
                </Row>
                {/* Meeting Participants */}
                <div>
                  <FieldArray name="meeting_participants">
                    {({ push, remove }) => (
                      <>
                        {values.meeting_participants.map(
                          (participant, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center mb-2 gap-3"
                            >
                              <CustomFormGroup
                                marginBottom="m-0"
                                md={5}
                                formlabel="Participant Name"
                                star="*"
                                FormField={
                                  <CustomInputField
                                    type="text"
                                    name={`meeting_participants[${index}].name`}
                                    placeholder="Enter Participant Name"
                                    error={
                                      touched.meeting_participants &&
                                      touched.meeting_participants[index]
                                        ?.name &&
                                      !!errors.meeting_participants?.[index]
                                        ?.name
                                    }
                                    helperText={
                                      errors.meeting_participants?.[index]?.name
                                    }
                                  />
                                }
                              />
                              <CustomFormGroup
                                marginBottom="m-0"
                                md={5}
                                formlabel="Voting Percentage"
                                star="*"
                                FormField={
                                  <CustomInputField
                                    type="number"
                                    name={`meeting_participants[${index}].voting_percentage`}
                                    placeholder="Enter Voting Percentage"
                                    error={
                                      touched.meeting_participants &&
                                      touched.meeting_participants[index]
                                        ?.voting_percentage &&
                                      !!errors.meeting_participants?.[index]
                                        ?.voting_percentage
                                    }
                                    helperText={
                                      errors.meeting_participants?.[index]
                                        ?.voting_percentage
                                    }
                                  />
                                }
                              />
                              <div className="mx-2 d-flex align-items-center gap-2">
                                <IconButton
                                  className="p-1"
                                  onClick={() =>
                                    push({ name: "", voting_percentage: "" })
                                  }
                                  color="primary"
                                  variant="outlined"
                                >
                                  <Plus />
                                </IconButton>
                                {index > 0 && (
                                  <IconButton
                                    className="p-1"
                                    variant="outlined"
                                    onClick={() => remove(index)}
                                    color="secondary"
                                  >
                                    <Minus />
                                  </IconButton>
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </>
                    )}
                  </FieldArray>
                </div>

                {/* Discussion Agendas */}
                <div>
                  <FieldArray name="discussion_agendas">
                    {({ push, remove }) => (
                      <>
                        {values.discussion_agendas.map((agenda, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center mb-2"
                          >
                            <CustomFormGroup
                              marginBottom="m-0"
                              md={10}
                              formlabel="Add Discussion Agendas"
                              star="*"
                              FormField={
                                <CustomInputField
                                  type="text"
                                  name={`discussion_agendas[${index}]`}
                                  placeholder="Enter Discussion Agenda"
                                  error={
                                    touched.discussion_agendas &&
                                    !!errors.discussion_agendas?.[index]
                                  }
                                  helperText={
                                    errors.discussion_agendas?.[index]
                                  }
                                />
                              }
                            />
                            <div className="mx-2 d-flex align-items-center gap-2">
                              <IconButton
                                className="p-1"
                                onClick={() => push("")}
                                color="primary"
                                variant="outlined"
                              >
                                <Plus />
                              </IconButton>
                              {index > 0 && (
                                <IconButton
                                  className="p-1"
                                  variant="outlined"
                                  onClick={() => remove(index)}
                                  color="secondary"
                                >
                                  <Minus />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </div>

                {/* Other Fields */}
                <CustomFormGroup
                  md={10}
                  formlabel="Description"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="textarea"
                      name="description"
                      placeholder="Description"
                      error={touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                    />
                  }
                />

                {/* File Upload Section */}
                <>
                  <Box
                    sx={{
                      border: "2px dashed #ccc",
                      padding: "20px",
                      borderRadius: "8px",
                      backgroundColor: isDragOver ? "#f0f0f0" : "#fff",
                      textAlign: "center",
                      cursor: "pointer",
                      height: "100px",
                    }}
                    onClick={handleClick}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <Typography className="d-flex align-items-center flex-column">
                      <ImageUp scale={50} />
                      {isDragOver
                        ? "Release to upload files"
                        : "Drag and drop files here or click to select files"}
                    </Typography>
                    <input
                      ref={fileInputRef}
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </Box>

                  {selectedFiles.length > 0 && (
                    <div>
                      <Typography variant="subtitle1">
                        Selected Files:
                      </Typography>
                      <List>
                        {selectedFiles.map((file, index) =>
                          renderFilePreview(file, index)
                        )}
                      </List>
                    </div>
                  )}
                </>

                <div className="d-flex justify-content-between align-items-center mt-4">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="cancel-button"
                    type="Button"
                    onClick={() => setAddMeeting(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="submit-button"
                    type={loading ? "button" : "submit"}
                  >
                    {loading ? "Loading..." : "Add Meeting"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        }
      />

      {/* <CustomModal
        ModalWidth={800}
        open={addMeeting}
        handleClose={() => setAddMeeting(false)}
        children={
          <Formik
            initialValues={{
              meeting_title: "",
              meeting_venue: "",
              start_date_time: "",
              voting_percentage: "",
              discussion_agendas: [""],
              circulated_notice_docs: [],
              description: "",
            }}
            onSubmit={handleAddMeeting}
          >
            {({ values, touched, errors, setFieldValue }) => (
              <Form>
                <Row className="align-items-center py-3">
                  <CustomFormGroup
                    formlabel="Meeting title"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="meeting_title"
                        placeholder="Enter Meeting title"
                        error={touched.meeting_title && !!errors.meeting_title}
                        helperText={
                          touched.meeting_title && errors.meeting_title
                        }
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="Meeting Venue"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="meeting_venue"
                        placeholder="Enter Meeting Venue"
                        error={touched.meeting_venue && !!errors.meeting_venue}
                        helperText={
                          touched.meeting_venue && errors.meeting_venue
                        }
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="Start Date and Time"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="datetime-local"
                        name="start_date_time"
                        error={
                          touched.start_date_time && !!errors.start_date_time
                        }
                        helperText={
                          touched.start_date_time && errors.start_date_time
                        }
                      />
                    }
                  />
                </Row>

                <div>
                  <FieldArray name="discussion_agendas">
                    {({ push, remove }) => (
                      <>
                        {values.discussion_agendas.map((agenda, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center mb-2"
                          >
                            <CustomFormGroup
                              marginBottom="m-0"
                              md={10}
                              formlabel="Add Discussion Agendas"
                              star="*"
                              FormField={
                                <CustomInputField
                                  type="text"
                                  name={`discussion_agendas[${index}]`}
                                  placeholder="Enter Discussion Agenda"
                                  error={
                                    touched.discussion_agendas &&
                                    !!errors.discussion_agendas?.[index]
                                  }
                                  helperText={
                                    errors.discussion_agendas?.[index]
                                  }
                                />
                              }
                            />
                            <div className="mx-2 d-flex align-items-center gap-2">
                              <IconButton
                                className="p-1"
                                onClick={() => push("")}
                                color="primary"
                                variant="outlined"
                              >
                                <Plus />
                              </IconButton>
                              {index > 0 && (
                                <IconButton
                                  className="p-1"
                                  variant="outlined"
                                  onClick={() => remove(index)}
                                  color="secondary"
                                >
                                  <Minus />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </div>

                <CustomFormGroup
                  md={10}
                  formlabel="Description"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="textarea"
                      name="description"
                      placeholder="Description"
                      error={touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                    />
                  }
                />

                <>
                  <Box
                    sx={{
                      border: "2px dashed #ccc",
                      padding: "20px",
                      borderRadius: "8px",
                      backgroundColor: isDragOver ? "#f0f0f0" : "#fff",
                      textAlign: "center",
                      cursor: "pointer",
                      height: "100px",
                    }}
                    onClick={handleClick} // Trigger file input click when clicked
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <Typography className="d-flex align-items-center flex-column">
                      <ImageUp scale={50} />
                      {isDragOver
                        ? "Release to upload files"
                        : "Drag and drop files here or click to select files"}
                    </Typography>
                    <input
                      ref={fileInputRef} // Referencing the input
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </Box>

                  {selectedFiles.length > 0 && (
                    <div>
                      <Typography variant="subtitle1">
                        Selected Files:
                      </Typography>
                      <List>
                        {selectedFiles.map((file, index) =>
                          renderFilePreview(file, index)
                        )}
                      </List>
                    </div>
                  )}
                </>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="cancel-button"
                    type="Button"
                    onClick={() => setAddMeeting(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="submit-button"
                    type={loading ? "button" : "submit"}
                  >
                    {loading ? "Loading..." : "Invite"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        }
      /> */}

      {/* <Dialog
        maxWidth="lg"
        open={addMeeting}
        onClose={() => setAddMeeting(false)}
        sx={{ overflow: "hidden" }}
      >
        <Formik
          initialValues={{
            meeting_title: "",
            meeting_venue: "",
            start_date_time: "",
            voting_percentage: "",
            discussion_agendas: [""],
            circulated_notice_docs: [],
            description: "",
          }}
          onSubmit={handleAddMeeting}
        >
          {({ values, touched, errors, setFieldValue }) => (
            <Form>
              <AppBar
                sx={{
                  position: "relative",
                  bgcolor: "#f4f6f8",
                  boxShadow: "0 0.1875rem 0.75rem 0 rgba(47, 43, 61, 0.14)",
                  color: "#3d3f4a",
                }}
              >
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setAddMeeting(false)}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
                    Schedule meeting and invite participants
                  </Typography>
                  <Button
                    disabled={loading}
                    color="inherit"
                    sx={{ fontWeight: 700, fontSize: "18px" }}
                    type="submit"
                  >
                    {loading ? "Loading..." : "Invite"}
                  </Button>
                </Toolbar>
              </AppBar>

              <Row className="align-items-center m-2 py-3">
                <CustomFormGroup
                  formlabel="Meeting title"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="text"
                      name="meeting_title"
                      placeholder="Enter Meeting title"
                      error={touched.meeting_title && !!errors.meeting_title}
                      helperText={touched.meeting_title && errors.meeting_title}
                    />
                  }
                />
                <CustomFormGroup
                  formlabel="Meeting Venue"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="text"
                      name="meeting_venue"
                      placeholder="Enter Meeting Venue"
                      error={touched.meeting_venue && !!errors.meeting_venue}
                      helperText={touched.meeting_venue && errors.meeting_venue}
                    />
                  }
                />
                <CustomFormGroup
                  formlabel="Start Date and Time"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="datetime-local"
                      name="start_date_time"
                      error={
                        touched.start_date_time && !!errors.start_date_time
                      }
                      helperText={
                        touched.start_date_time && errors.start_date_time
                      }
                    />
                  }
                />
                <CustomFormGroup
                  formlabel="Voting Percentage"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="text"
                      name="voting_percentage"
                      placeholder="Enter Voting Percentage"
                      error={
                        touched.voting_percentage && !!errors.voting_percentage
                      }
                      helperText={
                        touched.voting_percentage && errors.voting_percentage
                      }
                    />
                  }
                />

                <Col xs={6} md={6}>
                  <FieldArray name="discussion_agendas">
                    {({ push, remove }) => (
                      <>
                        {values.discussion_agendas.map((agenda, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center"
                          >
                            <CustomFormGroup
                              md={10}
                              formlabel="Add Discussion Agendas"
                              star="*"
                              FormField={
                                <CustomInputField
                                  type="text"
                                  name={`discussion_agendas[${index}]`}
                                  placeholder="Enter Discussion Agenda"
                                  error={
                                    touched.discussion_agendas &&
                                    !!errors.discussion_agendas?.[index]
                                  }
                                  helperText={
                                    errors.discussion_agendas?.[index]
                                  }
                                />
                              }
                            />
                            <Button onClick={() => push("")} color="primary">
                              +
                            </Button>
                            {index > 0 && (
                              <Button
                                onClick={() => remove(index)}
                                color="secondary"
                              >
                                -
                              </Button>
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </Col>

                <Box
                  sx={{
                    border: "2px dashed #ccc",
                    padding: "20px",
                    borderRadius: "8px",
                    backgroundColor: isDragOver ? "#f0f0f0" : "#fff",
                    textAlign: "center",
                    cursor: "pointer",
                    height: "100px",
                  }}
                  onClick={handleClick} // Trigger file input click when clicked
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <Typography>
                    {isDragOver
                      ? "Release to upload files"
                      : "Drag and drop files here or click to select files"}
                  </Typography>
                  <input
                    ref={fileInputRef} // Referencing the input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Box>

                // {/* File Preview 
                {selectedFiles.length > 0 && (
                  <div>
                    <Typography variant="subtitle1">Selected Files:</Typography>
                    <List>
                      {selectedFiles.map((file, index) =>
                        renderFilePreview(file, index)
                      )}
                    </List>
                  </div>
                )}

                <CustomFormGroup
                  formlabel="Description"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="textarea"
                      name="description"
                      placeholder="Description"
                      error={touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                    />
                  }
                />
              </Row>
            </Form>
          )}
        </Formik>
      </Dialog> */}
      <CustomModal
        ModalWidth={800}
        open={postMeeting.status}
        handleClose={() => setPostMeeting({ status: false, data: [] })}
        children={
          <>
            <h2 className="custom-heading mb-2">
              {postMeeting?.data?.meeting_title} Post Meeting
            </h2>
            <Divider />
            <div
              className="d-grid gap-2 mt-3"
              style={{ gridTemplateColumns: "1fr 1fr" }}
            >
              {[
                {
                  label: "Meeting Venue",
                  value: postMeeting?.data?.meeting_venue,
                },
                {
                  label: "Meeting Date",
                  value: postMeeting?.data?.start_date_time,
                },
                {
                  label: "Meeting Description",
                  value: postMeeting?.data?.description,
                },
              ].map((row, index) => (
                <div className="d-flex flex-row gap-3">
                  <h4
                    className="m-0"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#333333",
                    }}
                  >
                    {row?.label + " :- "}
                  </h4>
                  <p
                    className="m-0"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#6b7280",
                    }}
                  >
                    {row?.value}
                  </p>
                </div>
              ))}
            </div>
            {/* <h4>Circulated notice documents :-</h4> */}
            {/* {postMeeting?.data?.circulated_notice_docs &&
              JSON?.parse(postMeeting?.data?.circulated_notice_docs)?.map(
                (doc, index) => (
                  <div className="d-flex flex-row gap-3 mt-3">
                    <img src={doc} alt={doc} style={{ maxHeight: "100px" }} />
                  </div>
                )
              )} */}
            {postMeeting?.data?.discussion_agendas && (
              <>
                <h4
                  className="mt-3"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#333333",
                  }}
                >
                  Discussion Agendas :-{" "}
                </h4>
                <ul className="list-unstyled">
                  {JSON?.parse(postMeeting?.data?.discussion_agendas)?.map(
                    (agenda, index) => (
                      <li
                        className="m-0"
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#6b7280",
                        }}
                      >
                        {index + 1}. {agenda}
                      </li>
                    )
                  )}
                </ul>
              </>
            )}
            {postMeeting?.data?.meeting_participants && (
              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Meeting Participant</th>
                    <th>Voting Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {JSON?.parse(postMeeting?.data?.meeting_participants)?.map(
                    (participant) => (
                      <tr>
                        <td>{participant?.name}</td>
                        <td>{participant?.voting_percentage}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            )}
          </>
        }
      />
      {/* <Dialog
        maxWidth="lg"
        open={postMeeting.status}
        onClose={() => setPostMeeting({ status: false, data: [] })}
        sx={{ overflow: "hidden" }}
      >
        <DialogTitle>Post Meeting</DialogTitle>
        <Divider />
        <Formik
          initialValues={{
            meeting_minutes: "",
            meeting_results: "",
            resolution_proposed: "",
            decision_of_coc: "",
          }}
          onSubmit={handlePostMeeting}
        >
          {({ values, touched, errors }) => (
            <Form>
              <DialogContent>
                <div
                  className="d-grid gap-2"
                  style={{ gridTemplateColumns: "1fr 1fr" }}
                >
                  {postMeeting?.data?.map((row) => (
                    <div className="d-flex flex-row gap-3">
                      <h4
                        className="m-0"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#333333",
                        }}
                      >
                        {row?.label + " :- "}
                      </h4>
                      <p
                        className="m-0"
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#6b7280",
                        }}
                      >
                        {row?.value}
                      </p>
                    </div>
                  ))}
                </div>

                <Row className="mt-3">
                  <CustomFormGroup
                    formlabel="Minutes Upload"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="meeting_minutes"
                        placeholder="Minutes Upload"
                        error={
                          touched.meeting_minutes && !!errors.meeting_minutes
                        }
                        helperText={
                          touched.meeting_minutes && errors.meeting_minutes
                        }
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="Meeting Results"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="meeting_results"
                        placeholder="Minutes Results"
                        error={
                          touched.meeting_results && !!errors.meeting_results
                        }
                        helperText={
                          touched.meeting_results && errors.meeting_results
                        }
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="Resolution Proposed"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="resolution_proposed"
                        placeholder="Resolution Proposed"
                        error={
                          touched.resolution_proposed &&
                          !!errors.resolution_proposed
                        }
                        helperText={
                          touched.resolution_proposed &&
                          errors.resolution_proposed
                        }
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="Decision of CoC (Approved / Unapproved)"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="decision_of_coc"
                        placeholder="Decision of CoC (Approved / Unapproved)"
                        error={
                          touched.decision_of_coc && !!errors.decision_of_coc
                        }
                        helperText={
                          touched.decision_of_coc && errors.decision_of_coc
                        }
                      />
                    }
                  />
                </Row>
              </DialogContent>

              <DialogActions className="d-flex justify-content-between">
                <Button
                  onClick={() => setPostMeeting({ status: false, data: [] })}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={postisLoading}>
                  {postisLoading ? "Submitting..." : "Submit"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog> */}
    </>
  );
};

export default CirpMeeting;
