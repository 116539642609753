import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMui, useOther } from "../../hooks/Hooks";
import useCaseMeetingHook from "../../hooks/CIRP/useCaseMeetingHook";
import Table from "react-bootstrap/Table";
import { Formik, Field, Form } from "formik";

const PostMeeting = () => {
  const { Button } = useMui();
  const { useParams, decryptDataBase64Url, useQuery, notify, gobalUrl } =
    useOther();

  const { id } = useParams();
  const { meeting_data } = useParams();
  const meetingData = JSON.parse(decryptDataBase64Url(meeting_data));

  const { getCirpCaseMeetingAgendaHook, usePostMeetingAgendaHook } =
    useCaseMeetingHook();

  const { mutate, isLoading } = usePostMeetingAgendaHook();

  const AllCirpCaseMeetingAgenda = useQuery(
    [
      `all-cirp-case-meeting-agenda-${id}-${meetingData.cm_id}`,
      id,
      meetingData.cm_id,
    ],
    () => getCirpCaseMeetingAgendaHook({ id, cm_id: meetingData.cm_id }) // Passing both as an object
  );

  // Directly log the data if it's loaded
  if (AllCirpCaseMeetingAgenda.isLoading) {
    return <p>Loading...</p>;
  }

  console.log(meetingData.circulated_notice_docs);

  const initialValues = {
    agendas: AllCirpCaseMeetingAgenda?.data?.map((agendaItem) => ({
      cma_id: agendaItem.cma_id,
      agenda: agendaItem.agenda,
      resolution: agendaItem.resolution_propose || "",
      approval: agendaItem.approval || "",
      rejected: agendaItem.rejected || "",
      abstained: agendaItem.abstained || "",
      status: agendaItem.status || "Approval",
    })),
  };

  const handleSubmit = (values) => {
    const updatedAgendas = values.agendas.filter((agenda, index) => {
      const initialAgenda = initialValues.agendas[index];
      return (
        agenda.resolution !== initialAgenda.resolution ||
        agenda.approval !== initialAgenda.approval ||
        agenda.rejected !== initialAgenda.rejected ||
        agenda.abstained !== initialAgenda.abstained ||
        agenda.status !== initialAgenda.status
      );
    });

    mutate(updatedAgendas[0], {
      onSuccess: (response) => {
        console.log(response);
        if (response === "Post meeting agenda updated successfully") {
          notify("Post meeting agenda updated successfully", "success");
        } else {
          notify("Something went wrong", "error");
        }
      },
    }); // Only updated rows
  };

  return (
    <Card className="custom-card">
      <CardContent>
        <div className="d-flex justify-content-between align-items-center">
          <h6>{meetingData.meeting_title} Post Meeting</h6>
          <div>
            <Button variant="contained" onClick={() => window.history.back()}>
              Go Back
            </Button>
          </div>
        </div>
        <hr />
        <div
          className="d-grid gap-2 mt-3"
          style={{ gridTemplateColumns: "1fr 1fr" }}
        >
          {[
            {
              label: "Meeting Venue",
              value: meetingData.meeting_venue,
            },
            {
              label: "Meeting Date",
              value: meetingData.start_date_time,
            },
            {
              label: "Meeting Description",
              value: meetingData.description,
            },
          ].map((row, index) => (
            <div className="d-flex flex-row gap-3" key={index}>
              <h4
                className="m-0"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#333333",
                }}
              >
                {row?.label + " :- "}
              </h4>
              <p
                className="m-0"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#6b7280",
                }}
              >
                {row?.value}
              </p>
            </div>
          ))}
        </div>

        {meetingData.meeting_participants && (
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Meeting Participant</th>
                <th>Voting Percentage</th>
              </tr>
            </thead>
            <tbody>
              {JSON?.parse(meetingData.meeting_participants)?.map(
                (participant) => (
                  <tr key={participant?.name}>
                    <td>{participant?.name}</td>
                    <td>{participant?.voting_percentage}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        )}

        <hr />

        <h6
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "#000",
          }}
        >
          Meeting Agenda's :-
        </h6>

        {AllCirpCaseMeetingAgenda?.data && (
          <div className="mt-3">
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ values }) => (
                <Form>
                  {values.agendas.map((agendaItem, index) => (
                    <Accordion key={agendaItem.cma_id}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <Typography sx={{ color: "#000" }}>
                          {agendaItem.agenda}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table striped bordered hover className="mt-3">
                          <thead>
                            <tr>
                              <th>Resolution Proposed</th>
                              <th>Status</th>
                              <th>Approval %</th>
                              <th>Rejected %</th>
                              <th>Abstained %</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Field
                                  type="text"
                                  name={`agendas[${index}].resolution`}
                                  className="form-control"
                                  placeholder="Enter resolution"
                                />
                              </td>
                              <td>
                                <Field
                                  as="select"
                                  name={`agendas[${index}].status`}
                                  className="form-control"
                                >
                                  <option value="Approval">Approval</option>
                                  <option value="Rejected">Rejected</option>
                                  <option value="Abstained">Abstained</option>
                                </Field>
                              </td>
                              <td>
                                <Field
                                  type="number"
                                  name={`agendas[${index}].approval`}
                                  className="form-control"
                                  placeholder="Enter approval %"
                                />
                              </td>
                              <td>
                                <Field
                                  type="number"
                                  name={`agendas[${index}].rejected`}
                                  className="form-control"
                                  placeholder="Enter rejected %"
                                />
                              </td>
                              <td>
                                <Field
                                  type="number"
                                  name={`agendas[${index}].abstained`}
                                  className="form-control"
                                  placeholder="Enter abstained %"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                          <Button type="submit" variant="contained">
                            Submit
                          </Button>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Form>
              )}
            </Formik>
          </div>
        )}

        {meetingData.circulated_notice_docs.length > 0 &&
          meetingData.circulated_notice_docs.map((doc, index) => (
            <div className="mt-3" key={index}>
              <h4
                className="m-0"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#333333",
                }}
              >
                Circulated Notice Document {index + 1} :-{" "}
              </h4>
              {/* <iframe
                src={gobalUrl + doc}
                width="100%"
                height="500px"
                title="Document"
              ></iframe> */}

              <button
                onClick={() => window.open(gobalUrl + doc, "_blank")}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                View Document
              </button>
            </div>
          ))}
      </CardContent>
    </Card>
  );
};

export default PostMeeting;
