import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import CIRPHeader from "../../components/CIRPHeader";
import EditIcon from "@mui/icons-material/Edit"; // For the edit button icon
import CustomDialog from "../../components/CustomDialog";
import useCaseSummaryHook from "../../hooks/CIRP/useCaseSummaryHook";
import useCIRPMilestoneHook from "../../hooks/useCIRPMilestoneHook";
import { useOther } from "../../hooks/Hooks";


const CirpViewClaim = () => {
  const {useClaimCommentHook,getClaimCommentHook} = useCaseSummaryHook();
  const location = useLocation();
  const {useQuery, toast} = useOther();
  const navigate = useNavigate();
  const {id,clam_id} = useParams();
  const [CommentDialog, setCommentDialog] = useState(false);
  const [open, setOpen] = useState(false); // State to manage dialog visibility

  const getAllCommentHistory = useQuery("getAllCommentHistory" + clam_id, () => getClaimCommentHook(clam_id));
  const { useSetUpdateAssignmentClaim } = useCIRPMilestoneHook();
  const { mutate: mutateClaimUpdated, isLoading: isLoadingClaim } = useSetUpdateAssignmentClaim();
  const {mutate, isLoading} = useClaimCommentHook();

  const notify = (msg, type) => {
    type === "success" ? toast.success(msg) : toast.error(msg);
  };

  const { row } = location.state || {};

  useEffect(() => {
    if (!row) {
      navigate("/some-fallback-path");
    }
  }, [row, navigate]);

  const BasicDetails = [
    {
      title: "Claimant Name",
      description: row?.claim_name,
      field: "claim_name",
    },
    { title: "Persona", description: row?.claim_type, field: "claim_type" },
    {
      title: "Principal Amount Claimed",
      description: row?.principal_amount_claim,
      field: "principal_amount_claim",
    },
    {
      title: "Interest Amount Claimed",
      description: row?.interest_amount_claim,
      field: "interest_amount_claim",
    },
    {
      title: "Date Received",
      description: row?.claim_date,
      field: "claim_date",
    },
    { title: "Claim Source", description: row?.source, field: "source" },
    { title: "Status", description: row?.status, field: "status" },
  ];

  const ClaimAmountDetails = [
    {
      title: "Principal Amount Claimed",
      description: row?.principal_amount_claim,
      field: "principal_amount_claim",
    },
    {
      title: "Interest Amount Claimed",
      description: row?.interest_amount_claim,
      field: "interest_amount_claim",
    },
    {
      title: "Total Amount Claimed",
      description:
        (row?.principal_amount_claim || 0) + (row?.interest_amount_claim || 0),
    },
  ];

  const BankDetails = [
    {
      title: "Name of the Beneficiary",
      field: "beneficiary_name",
      description: row?.beneficiary_name,
    },
    {
      title: "Name of the Bank",
      field: "bank_name",
      description: row?.bank_name,
    },
    {
      title: "Type of Account",
      field: "account_type",
      description: row?.account_type,
    },
    {
      title: "Account Number",
      field: "account_number",
      description: row?.account_number,
    },
    { title: "Branch", field: "branch", description: row?.branch },
    { title: "IFSC Code", field: "ifsc_code", description: row?.ifsc_code },
  ];

  const DetailsSecurity = [
    {
      title: "Type of Security",
      field: "type_of_security",
      description: row?.type_of_security,
    },
    {
      title: "Date of Creation",
      field: "date_of_creation",
      description: row?.date_of_creation,
    },
    {
      title: "Details of Security",
      field: "details_of_security",
      description: row?.details_of_security,
    },
    {
      title: "Amount of Security",
      field: "amount_of_security",
      description: row?.amount_of_security,
    },
    { title: "Ownership and Title", field: "ownership_and_title", description: row?.ownership_and_title },
    { title: "Third Party Guarantee", field: "third_party_guarantee", description: row?.third_party_guarantee },
    { title: "Amount of Guarantee", field: "amount_of_guarantee", description: row?.amount_of_guarantee },
  ];

  const handleEdit = () => {
    setOpen(true); // Open the form dialog on edit
  };

  const handleClose = () => {
    setOpen(false); // Close the form dialog
  };

  const handleSubmit = (values) => {
    console.log("Updated Values:", values);
    mutateClaimUpdated(
      { ...values, id },
      {
        onSuccess: (response) => {
          if (response.data === "Claim Updated Successfully") {
            handleClose();
            row.refetch();
          } else {
            notify("Something went wrong", "error");
          }
        },
      }
    );
    // handleClose();
  };

  return (
    <Card
      className="custom-card mt-3 p-3"
      style={{
        borderRadius: "8px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
        backgroundColor: "#f8f9fa",
      }}
    >
      <Card.Header>
        <CIRPHeader />
      </Card.Header>

      <Card.Body>
        <div className="m-3 d-flex justify-content-end gap-2">
          <Button
            variant="outlined"
            size="small"
            startIcon={<EditIcon />}
            onClick={handleEdit}
          >
            Edit Details
          </Button>
          <Button variant="outlined" size="small" onClick={() => setCommentDialog(true)}>
            Comments
          </Button>
        </div>

        {/* Accordion for General Details */}
        <Accordion
          className="mt-3"
          style={{
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{
              backgroundColor: "#7367f0",
              color: "#ffffff",
              border: "none",
            }}
          >
            <h5 style={{ fontSize: "18px", fontWeight: "600", margin: "0" }}>
              General Details
            </h5>
          </AccordionSummary>
          <AccordionDetails>
            <Row>
              {BasicDetails.map((detail, index) => (
                <Col key={index} md={4} sm={6} className="px-5 py-3">
                  <h4
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#6b7280",
                    }}
                  >
                    {detail.title}
                  </h4>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#333333",
                    }}
                  >
                    {detail.description || "N/A"}
                  </p>
                </Col>
              ))}
            </Row>
          </AccordionDetails>
        </Accordion>

        {/* Accordion for Claim Amount Details */}
        <Accordion
          className="mt-3"
          style={{
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ backgroundColor: "#7367f0", color: "#ffffff" }}
          >
            <h5 style={{ fontSize: "18px", fontWeight: "600", margin: "0" }}>
              Claim Amount Details
            </h5>
          </AccordionSummary>
          <AccordionDetails>
            <Row>
              {ClaimAmountDetails.map((detail, index) => (
                <Col key={index} md={4} sm={6} className="px-5 py-3">
                  <h4
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#6b7280",
                    }}
                  >
                    {detail.title}
                  </h4>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#333333",
                    }}
                  >
                    {detail.description || "N/A"}
                  </p>
                </Col>
              ))}
            </Row>
          </AccordionDetails>
        </Accordion>

        {/* Accordion for Bank Details */}
        <Accordion
          className="mt-3"
          style={{
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ backgroundColor: "#7367f0", color: "#ffffff" }}
          >
            <h5 style={{ fontSize: "18px", fontWeight: "600", margin: "0" }}>
              Bank Details
            </h5>
          </AccordionSummary>
          <AccordionDetails>
            <Row>
              {BankDetails.map((detail, index) => (
                <Col key={index} md={4} sm={6} className="px-5 py-3">
                  <h4
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#6b7280",
                    }}
                  >
                    {detail.title}
                  </h4>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#333333",
                    }}
                  >
                    {detail.description || "N/A"}
                  </p>
                </Col>
              ))}
            </Row>
          </AccordionDetails>
        </Accordion>

        {/* Accordion for Details of Security */}
        <Accordion
          className="mt-3"
          style={{
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ backgroundColor: "#7367f0", color: "#ffffff" }}
          >
            <h5 style={{ fontSize: "18px", fontWeight: "600", margin: "0" }}>
              Details of Security
            </h5>
          </AccordionSummary>
          <AccordionDetails>
            <Row>
              {DetailsSecurity.map((detail, index) => (
                <Col key={index} md={4} sm={6} className="px-5 py-3">
                  <h4
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#6b7280",
                    }}
                  >
                    {detail.title}
                  </h4>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#333333",
                    }}
                  >
                    {detail.description || "N/A"}
                  </p>
                </Col>
              ))}
            </Row>
          </AccordionDetails>
        </Accordion>
      </Card.Body>
      <CustomDialog
      width={900}
      open={CommentDialog}
      handleDialogClose={() => setCommentDialog(false)}
      dialogTitle={"Comments of Claim " + row.claim_name}
>
  <Row>

    <Col md={6} lg={6}>
    <Formik
    initialValues={{
      commentType: "", // Initial value for comment type
      comment: "", // Initial value for the comment
    }}
    onSubmit={(values) => {
      mutate({...values, cirp_id: id, claim_id: clam_id},{
        onSuccess: (response) => {
          getAllCommentHistory.refetch();
          setCommentDialog(false); // Close the dialog after submission
        },
      });
      setCommentDialog(false); // Close the dialog after submission
    }}
  >
    {({ values, handleChange }) => (
      <Form>
        <div style={{ marginBottom: "16px" }}>
          <label
            htmlFor="commentType"
            style={{ fontWeight: "600", color: "#333", display: "block" }}
          >
            Select Comment Type
          </label>
          <Field
            as="select"
            name="commentType"
            id="commentType"
            className="form-control"
            style={{
              borderRadius: "4px",
              border: "1px solid #ced4da",
              marginTop: "8px",
            }}
          >
            <option value="">Select</option>
            <option value="Comment To">Comment To</option>
            <option value="Comment Raised">Comment Raised</option>
          </Field>
        </div>

        <div style={{ marginBottom: "16px" }}>
          <label
            htmlFor="comment"
            style={{ fontWeight: "600", color: "#333", display: "block" }}
          >
            Enter Your Comment
          </label>
          <Field
            as="textarea"
            name="comment"
            id="comment"
            rows="4"
            className="form-control"
            style={{
              borderRadius: "4px",
              border: "1px solid #ced4da",
              marginTop: "8px",
            }}
          />
        </div>

        <DialogActions className="justify-content-between align-items-center">
          <Button
            onClick={() => setCommentDialog(false)}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Form>
    )}
  </Formik>
    </Col>

    <Col md={6} lg={6}>
        <div style={{ marginBottom: "20px", maxHeight: "300px", overflowY: "auto" }}>
          <h4 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "10px" }}>
            Comment Timeline
          </h4>
          {getAllCommentHistory.isLoading ? (
            <p>Loading comments...</p>
          ) : getAllCommentHistory.data?.length > 0 ? (
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {getAllCommentHistory.data.map((comment, index) => (
                <li
                  key={index}
                  style={{
                    marginBottom: "15px",
                    borderBottom: "1px solid #e0e0e0",
                    paddingBottom: "10px",
                  }}
                >
                  <p style={{ margin: 0,fontSize: "14px", color: "#333" }}>
                    {"Comment Type :- " + comment.comment_type}
                  </p>
                  <p style={{ margin: "5px 0", fontSize: "14px", color: "#555" }}>
                    {"Comment :- " + comment.comment}
                  </p>
                  <span style={{ fontSize: "12px", color: "#888" }}>
                    {"Created Date & Time :- " + new Date(comment.created_dt).toLocaleString()}
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p>No comments available.</p>
          )}
        </div>
    </Col>
  </Row>
</CustomDialog>

      {/* Formik Dialog for editing details */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Details</DialogTitle>
        <DialogContent>
          <Formik initialValues={{ ...row }} onSubmit={handleSubmit}>
            {() => (
              <Form>
                <h4>General Details</h4>
                <Row>
                  {BasicDetails.map((detail, index) => (
                    <Col key={index} md={6} className="mb-3">
                      <label style={{ fontWeight: "600", color: "#333" }}>
                        {detail.title}
                      </label>
                      <Field
                        name={detail.field}
                        className="form-control"
                        style={{
                          borderRadius: "4px",
                          border: "1px solid #ced4da",
                        }}
                      />
                    </Col>
                  ))}
                </Row>

                <h4>Bank Details</h4>
                <Row>
                  {BankDetails.map((detail, index) => (
                    <Col key={index} md={6} className="mb-3">
                      <label style={{ fontWeight: "600", color: "#333" }}>
                        {detail.title}
                      </label>
                      <Field
                        name={detail.field}
                        className="form-control"
                        style={{
                          borderRadius: "4px",
                          border: "1px solid #ced4da",
                        }}
                      />
                    </Col>
                  ))}
                </Row>

                <h4>Details of Security</h4>
                <Row>
                  {DetailsSecurity.map((detail, index) => (
                    <Col key={index} md={6} className="mb-3">
                      <label style={{ fontWeight: "600", color: "#333" }}>
                        {detail.title}
                      </label>
                      <Field
                        name={detail.field}
                        className="form-control"
                        style={{
                          borderRadius: "4px",
                          border: "1px solid #ced4da",
                        }}
                      />
                    </Col>
                  ))}
                </Row>

                <DialogActions>
                  <Button onClick={handleClose} color="secondary">
                    Cancel
                  </Button>
                  <Button type="submit" isLoading={isLoadingClaim} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default CirpViewClaim;
