import axios from "../services/axios";
import { useOther } from "./Hooks";

const useAttendanceHook = () => {
  const { useMutation, systemDT, Cookies } = useOther();

  const getUserAttendance = async () => {
    try {
      const response = await axios.post(`/attendance/get-user-attendance`, {
        user_id: Cookies.get("user_id"),
      });
      return response;
    } catch (err) {
      return err;
    }
  };

  return { getUserAttendance };
};

export default useAttendanceHook;
