import React from "react";
import { Box, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import CIRPHeader from "../../components/CIRPHeader";
import CustomDialog from "../../components/CustomDialog";
import { useOther } from "../../hooks/Hooks";
import { Card } from "react-bootstrap";
import axios from "../../services/axios";
import { useQueryClient } from "react-query";
import FileCard from "../../components/FileCard";
import NoDataImg from "./../../assets/no-data.svg";
import useCIRPMilestoneHook from "../../hooks/useCIRPMilestoneHook";

const CirpDocumentPage = () => {
  const { id } = useParams();
  const { useMutation, systemDT, useQuery, notify } = useOther();

  const { getDocument } = useCIRPMilestoneHook();

  const [title, setTitle] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [dragActive, setDragActive] = React.useState(false);
  const [AddDocumentModal, setAddDocumentModal] = React.useState(false);

  const queryClient = useQueryClient();

  const DocumentData = useQuery("document-details-" + id, () => {
    return getDocument(id);
  });

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(e.type === "dragenter" || e.type === "dragover");
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  // Function to send the data to the backend API
  const addDocument = async (formData) => {
    const response = await axios.post(`/cirp-milestone/upload-doc`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    DocumentData.refetch();
    return response.data;
  };

  const mutation = useMutation(addDocument, {
    onSuccess: () => {
      notify("Document uploaded successfully", "success");
      queryClient.invalidateQueries("documents");
      setAddDocumentModal(false);
    },
    onError: (error) => {
      notify("Something went wrong", "error");
      console.error("Error uploading file:", error.message);
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
      }
    },
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("DateTime", systemDT);
    formData.append("assignment_id", id);
    formData.append("title", title);

    for (let [key, value] of formData.entries()) {
      console.log(key, value); // Log FormData contents
    }

    mutation.mutate(formData); // Use mutation to submit
  };

  const handleDeleteDocument = (data) => {};

  return (
    <>
      <Card className="custom-card">
        <Card.Header>
          <CIRPHeader />
        </Card.Header>

        {/* Add Document Modal */}
        <CustomDialog
          open={AddDocumentModal}
          handleDialogClose={() => setAddDocumentModal(false)}
          dialogTitle="Add CIRP Stage"
        >
          <form onSubmit={handleFormSubmit} encType="multipart/form-data">
            <div className="my-3">
              <label>File Name :</label>
              <input
                type="text"
                className="custom-input"
                placeholder="Enter File Name"
                value={title}
                onChange={handleTitleChange}
                required
              />
            </div>
            <label>Document File :</label>
            <div
              onDragEnter={handleDrag}
              onDragOver={handleDrag}
              onDragLeave={handleDrag}
              onDrop={handleDrop}
              style={{
                border: "2px dashed #ccc",
                padding: "20px",
                textAlign: "center",
                backgroundColor: dragActive ? "#f9f9f9" : "#fff",
              }}
            >
              <input
                type="file"
                name="document_file"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id={`file-upload-document-file`}
              />
              <label
                htmlFor={`file-upload-document-file`}
                style={{ cursor: "pointer" }}
              >
                {file ? file.name : "Drag and drop a file or click to upload"}
              </label>
            </div>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button
                type="submit"
                color="primary"
                disabled={mutation.isLoading}
                className="mt-3"
              >
                {mutation.isLoading ? "Uploading..." : "Upload"}
              </Button>
              <Button onClick={() => setAddDocumentModal(false)}>Cancel</Button>
            </Box>
          </form>
        </CustomDialog>
      </Card>
      <Card className="custom-card mt-3">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Card.Title>All Document</Card.Title>
          <Button onClick={() => setAddDocumentModal(true)} variant="contained">
            Upload Document
          </Button>
        </Card.Header>
        <Card.Body>
          {DocumentData.data?.length > 0 ? (
            <div className="d-flex flex-wrap justify-content-start p-2">
              {DocumentData.data?.map((file) => (
                <FileCard
                  key={file.assignment_document_id}
                  file={file}
                  onDelete={() => handleDeleteDocument(file)}
                />
              ))}
            </div>
          ) : (
            <div className="text-center mb-5">
              <img src={NoDataImg} alt="No data" className="mb-5" />
              <h5>No records found. click on Add to create</h5>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default CirpDocumentPage;
