// AddCirpPage.js
import React, { useState } from "react";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useBootstrap, useOther } from "../../hooks/Hooks";
import FormUtils from "../../utils/FormUtils";
import * as Yup from "yup";
import useCIRPHook from "../../hooks/useCIRPHook";
import Cookies from "js-cookie";
import useUserHooks from "../../hooks/useUserHooks";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const steps = ["CIRP Process", "Setup Support Team"];

const AddCirpPage = () => {
  const { Card, Row } = useBootstrap();
  const { Form, Formik, useNavigate, dayjs, useQuery, notify } = useOther();
  const { CustomInputField, CustomFormGroup } = FormUtils();
  const { useAddCIRPProcessHook } = useCIRPHook();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const [memberData, setMemberData] = useState({
    user_id: "",
    role: "",
  });

  const [teamMembers, setTeamMembers] = useState([
    {
      user_id: Number(Cookies.get("user_id")),
      role: "IP",
    },
  ]);

  const { getDropdownUser } = useUserHooks();

  const AllUser = useQuery("AllUser", () => {
    return getDropdownUser();
  });

  const { mutate, isLoading } = useAddCIRPProcessHook();

  const handleNext = (values) => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      mutate(
        { ...values, teamMembers: teamMembers },
        {
          onSuccess: (data) => {
            notify("CIRP Process added successfully", "success");
            navigate("/process/cirp"); // Navigate after successful submission
          },
          onError: (error) => {
            console.error("Error submitting form: ", error);
            notify("Something went wrong, please try again", "error");
          },
        }
      );
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleMemberChange = (e) => {
    setMemberData({ ...memberData, [e.target.name]: e.target.value });
  };

  const handleAddMember = () => {
    if (memberData.user_id && memberData.role) {
      // Append new member to the teamMembers list
      setTeamMembers([...teamMembers, memberData]);

      // Clear the form data
      setMemberData({ user_id: "", role: "" });

      handleDialogClose();
    } else {
      alert("Please select both a user and a role.");
    }
  };

  const validationSchema = Yup.object().shape({
    cd_name: Yup.string().required("Corporate Debtor Name is required"),
    cin_no: Yup.string().required("CIN No. is required"),
    applicant_name: Yup.string().required("Applicant Name is required"),
    applicant_email: Yup.string()
      .email("Invalid email format")
      .required("Applicant Email is required"),
    nclt_order_no: Yup.string().required("NCLT Order No. is required"),
    stage: Yup.string().required("Stage is required"),
    default_amount: Yup.number().required("Default Amount is required"),
    order_date: Yup.date().required("Order Date is required"),
    receipt_date: Yup.date().required("Receipt Date is required"),
    application_filing_date: Yup.date().required(
      "Application Filing Date is required"
    ),
  });

  const handleRemoveMember = (index) => {
    const updatedMembers = teamMembers.filter((_, i) => i !== index);
    setTeamMembers(updatedMembers);
  };

  const getUsernameById = (user_id) => {
    const user = AllUser?.data?.results.find(
      (user) => user.user_id === user_id
    );
    return user ? user.username : "Unknown User";
  };

  if (AllUser.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Card className="custom-card">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div>
          <h5 className="mb-0">Setting up CIRP Process</h5>
          <span className="custom-card-subtitle">Step 1 of 2</span>
        </div>
        <Button variant="contained" onClick={() => navigate("/process/cirp")}>
          Cancel
        </Button>
      </Card.Header>
      <Card.Body className="p-3">
        <Formik
          initialValues={{
            cd_name: "",
            cin_no: "",
            applicant_name: "",
            applicant_email: "",
            stage: "IRP",
            nclt_order_no: "",
            receipt_date: dayjs().format("YYYY-MM-DD"),
            order_date: dayjs().format("YYYY-MM-DD"),
            application_filing_date: dayjs().format("YYYY-MM-DD"),
            default_amount: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleNext}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                {activeStep === 0 && (
                  <Row>
                    <CustomFormGroup
                      formlabel="Corporate Debtor Name"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="cd_name"
                          placeholder="Enter Corporate Debtor Name"
                          error={touched.cd_name && !!errors.cd_name}
                          helperText={touched.cd_name && errors.cd_name}
                        />
                      }
                    />
                    <CustomFormGroup
                      formlabel="CIN No."
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="cin_no"
                          placeholder="Enter CIN No."
                          error={touched.cin_no && !!errors.cin_no}
                          helperText={touched.cin_no && errors.cin_no}
                        />
                      }
                    />
                    <CustomFormGroup
                      formlabel="Applicant Name"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="applicant_name"
                          placeholder="Enter Applicant Name"
                          error={
                            touched.applicant_name && !!errors.applicant_name
                          }
                          helperText={
                            touched.applicant_name && errors.applicant_name
                          }
                        />
                      }
                    />
                    <CustomFormGroup
                      formlabel="Applicant Email"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="email"
                          name="applicant_email"
                          placeholder="Enter Applicant Email"
                          error={
                            touched.applicant_email && !!errors.applicant_email
                          }
                          helperText={
                            touched.applicant_email && errors.applicant_email
                          }
                        />
                      }
                    />
                    <CustomFormGroup
                      formlabel="NCLT Order No"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="nclt_order_no"
                          placeholder="Enter NCLT Order No."
                          error={
                            touched.nclt_order_no && !!errors.nclt_order_no
                          }
                          helperText={
                            touched.nclt_order_no && errors.nclt_order_no
                          }
                        />
                      }
                    />
                    <CustomFormGroup
                      formlabel="Stage"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="stage"
                          placeholder="Enter Stage"
                          error={touched.stage && !!errors.stage}
                          helperText={touched.stage && errors.stage}
                        />
                      }
                    />
                    <CustomFormGroup
                      formlabel="Default Amount"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="number"
                          name="default_amount"
                          placeholder="Enter Default Amount"
                          error={
                            touched.default_amount && !!errors.default_amount
                          }
                          helperText={
                            touched.default_amount && errors.default_amount
                          }
                        />
                      }
                    />
                    <CustomFormGroup
                      formlabel="Order Date"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="date"
                          name="order_date"
                          error={touched.order_date && !!errors.order_date}
                          helperText={touched.order_date && errors.order_date}
                        />
                      }
                    />
                    <CustomFormGroup
                      formlabel="Receipt Date"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="date"
                          name="receipt_date"
                          error={touched.receipt_date && !!errors.receipt_date}
                          helperText={
                            touched.receipt_date && errors.receipt_date
                          }
                        />
                      }
                    />
                    <CustomFormGroup
                      formlabel="Application Filing Date"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="date"
                          name="application_filing_date"
                          error={
                            touched.application_filing_date &&
                            !!errors.application_filing_date
                          }
                          helperText={
                            touched.application_filing_date &&
                            errors.application_filing_date
                          }
                        />
                      }
                    />
                  </Row>
                )}

                {activeStep === 1 && (
                  <>
                    <Button variant="contained" onClick={handleDialogOpen}>
                      Add Members
                    </Button>
                    <Table className="w-50">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>User</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Role</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Actions</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {teamMembers.map((member, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {getUsernameById(member.user_id)}
                            </TableCell>
                            <TableCell>{member.role}</TableCell>
                            <TableCell>
                              <Button
                                color="secondary"
                                onClick={() => handleRemoveMember(index)}
                              >
                                Remove
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 3,
                  }}
                >
                  <Button
                    variant="contained"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Card.Body>

      {/* Dialog for Adding Members */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Add New Member</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Select User</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={memberData.user_id}
              label="Select User"
              name="user_id"
              onChange={handleMemberChange}
            >
              {AllUser?.data?.results
                .filter(
                  (user) =>
                    !teamMembers.some(
                      (member) => member.user_id === user.user_id
                    )
                )
                .map((user) => (
                  <MenuItem key={user.user_id} value={user.user_id}>
                    {user.username}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={memberData.role}
              label="Select User"
              name="role"
              onChange={handleMemberChange}
            >
              <MenuItem value={"Admin"}>Admin</MenuItem>
              <MenuItem value={"Team Leader"}>Team Leader</MenuItem>
              <MenuItem value={"Manager"}>Manager</MenuItem>
              <MenuItem value={"Member"}>Member</MenuItem>
              <MenuItem value={"Guest"}>Guest</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddMember} color="primary">
            Add Member
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default AddCirpPage;
